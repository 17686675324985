<script>
import PageHeader from "@/components/page-header";
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { ApiClientSelectedPlatform } from "@/common/http-common"
export default {
    components: {
        PageHeader
    },
    data() {
        return {
            title: this.$t('localizations.configuration'),
            items: [],
            permissionsList: null
        };
    },
    methods: {
        checkPermission: function (response, p) {
            var returnValue = false
            response?.data?.item?.permissions?.forEach(function (permission) {
                if (permission.permission.type == p) {
                    returnValue = true
                }
            })
            return returnValue
        },
        getPermissions: function () {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";
            var rs = JSON.parse(atob(this.$route.query.data))
            ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/user/permission").then((response) => {
                this.permissionsList = response
                this.getModules()
            }).catch(
                function (error) {
                    networkRequesErrorResponseLogic(self, error)
                }
            )
        },
        getModules() {
            const self = this;
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";
            var rs = JSON.parse(atob(this.$route.query.data))
            ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/localization").then(function (response) {
                self.items = response.data.items
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
                BredcrumpManagerEncode(self, { path: self.$route.path, title: 'localizations.configuration' })
            }).catch(
                function (error) {
                    networkRequesErrorResponseLogic(self, error)
                }
            )
        },
        submitForm() {
            const self = this;
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";
            var rs = JSON.parse(atob(this.$route.query.data))
            const json = JSON.stringify(self.items);

            ApiClientSelectedPlatform(rs.platformId).put("/v1/admin/localization", json).then(function (response) {
                self.items = response.data.items
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
            }).catch(
                function (error) {
                    networkRequesErrorResponseLogic(self, error)
                }
            )
        }
    },
    mounted() {
        this.getPermissions()
    }
};
</script>
<template>
    <div class="row">
        <div class="col-xl-12" style="padding: 0!important;">
            <PageHeader :title="title" />
        </div>
        <div class="col-xl-12" v-if="permissionsList != null">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ this.$t('localizations.configuration') }}</h4>
                    <p class="card-title-desc">
                    </p>
                    <div class="row">
                        <div class="col-12">
                            <form class="form-horizontal" role="form" @submit.prevent="submitForm">

                                <div class="col-md-12" v-for="(item, index) in items" :key="index">
                                    <div class="row">
                                        <div class="custom-control custom-checkbox mb-3 col-4">
                                            <input type="checkbox" class="custom-control-input" v-model="item.selected"
                                                :id="item.id" />
                                            <label class="custom-control-label" style="font-size:0.8rem!important"
                                                :for="item.id">{{
                item.name }}</label>
                                        </div>
                                    </div>

                                </div>
                                <b-button v-if="checkPermission(permissionsList, 'MODULE_WRITE_DOMAIN_LOCALIZATION')"
                                    variant="primary" type="submit">{{
                                    $t('buttons.save') }}</b-button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>